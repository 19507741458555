import AboutSmall from "../../assets/images/theme-2/AboutSmall.svg";
import Aboutbg from "../../assets/images/theme-2/about-img.jpg";
import Header from "../../componet/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./BlogList.css";
import Footer from "../../componet/Footer";
export const baseUrl = "https://api.anubhaanant.com/api";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/rp/articles/all`);
        setBlogs(response.data.data);
      } catch (error) {
        setError("Failed to fetch blogs. Please try again later.");
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  const openBlog = (id) => {
    navigate(`/blogs/${id}`);
  };

  const getExcerpt = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent.slice(0, 100) + "...";
  };

  return (
    <>
      <Header />
      <div className="about-bg">
        <img className="d-sm-block d-md-none" src={AboutSmall} loading="lazy" />
        <img
          className="d-sm-none d-md-block d-none"
          src={Aboutbg}
          loading="lazy"
        />
      </div>
      <div className="blog-list-container">
        <h1 className="blog-list-title">Latest Blogs</h1>

        {loading ? (
          <div className="loader">Loading blogs...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : blogs.length > 0 ? (
          <div className="blog-cards">
            {blogs.map((blog) => (
              <div
                key={blog.id}
                className="blog-card"
                onClick={() => openBlog(blog.id)}
              >
                <img
                  src={blog.featureImageUrl}
                  alt={blog.title}
                  className="blog-image"
                  loading="lazy"
                />
                <div className="blog-details">
                  <h2 className="blog-title">{blog.title}</h2>
                  <p className="blog-author">By {blog.author.name}</p>
                  <p className="blog-date">
                    {new Date(blog.publishedDate).toLocaleDateString()}
                  </p>
                  <p className="blog-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blog.content + "...",
                      }}
                    ></div>
                    <span
                      className="read-more-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        openBlog(blog.id);
                      }}
                    >
                      Read More
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-blogs-message">
            No blogs available at the moment.
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default BlogList;
